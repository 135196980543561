import * as React from 'react';
import { withTrans } from '../i18n/withTrans';

import { pageTexts } from '../content/trendbook-ss24';

import trendbookPDF from '../files/rz-ispo-textrends-spring-summer-24.pdf';

// components
import Layout from '../components/layout';
import TextImgBackgroundFull from '../components/textImgBackgroundFull';

class trendbookSS24 extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.content = pageTexts(this.t);
  }

  render() {
    return (
      <Layout pageName='trendbook-ss24' whiteMenu={false}>
        <TextImgBackgroundFull info={this.content.topInfo}></TextImgBackgroundFull>
        <div className='row justify-content-center pt-50 pb-75'>
          <a href={this.content.cta.url} target='_blank' rel='noreferrer'>
            <button className='mainColor bigWidth ctaButton'>{this.content.cta.text}</button>
          </a>
        </div>
        <div className='d-flex justify-content-center pb-20'>
          <embed src={trendbookPDF} width='800px' height='1150px' />
        </div>
      </Layout>
    );
  }
}

export default withTrans(trendbookSS24);
