//images
import TopBackground from '../images/trendbook-banner.jpg';

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t('trendbook-ss24.topTitle'),
        colorClass: 'colorLight',
      },
      bottomDiagonal: true,
      opacity: true,
      full: true,
      image: {
        smallHeight: true,
        src: TopBackground,
        alt: t('trendbook-ss24.imgAlt'),
        bottomPosition: true,
      },
    },
    cta: {
      text: t('trendbook-ss24.cta.text'),
      url: t('trendbook-ss24.cta.link'),
      type: 'mainColor',
      extraClass: 'bigWidth',
    },
  };
};
